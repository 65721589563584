import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/language/language.module.scss";
import { IoMdClose } from "react-icons/io";
import { MdFilterListAlt } from "react-icons/md";
import { LuPlusCircle } from "react-icons/lu";
import { BsSortDown } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BiCheck } from "react-icons/bi";
// import { PiDotsThreeOutlineFill } from "react-icons/pi";
// import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import Pagination from "../common/Pagination";
import DeleteIllustration from "../../assets/images/deleteIllustrator.png";
import axios from "axios";
import { baseUrl } from '../../utils/constant';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { popUpBoxTrigger } from '../../features/message/popUpSlice';
// import { updatePage } from '../../features/pagination/paginationSlice';
import ContentLoaderGif from "../../assets/images/contentLoader.gif";
import DefaultLanguageImage from "../../assets/images/Admin/Profile/languagelogo.jpg";
 
 
const Language = () => {
 
    const [isOpen, setIsOpen] = useState(false);
    const [isSortOpen, setIsSortOpen] = useState(false);
    const [contentLoader, setContentLoader] = useState('true');
 
    // const [sortingValue, setSortingValue] = useState("asc");
 
    const [statusFilter, setStatusFilter] = useState('');
    const [filterDate, setFilterDate] = useState({
      from: '',
      to: ''
    });
    const [languageFilter, setLanguageFilter] = useState({
      from: '',
      to: '',
      search: '',
      sorting: 'desc',
      page: "1",
      limit: "5"
    });
 
    const [pagination, setPagination] = useState({
      currentPage: 1,
      totalPage: 6,
      showing: 5
    });
 
    const dateStatusApply = () => {
      setLanguageFilter({...languageFilter, from: filterDate.from, to: filterDate.to, status: statusFilter});
      setIsOpen(false);
    };
 
    const handleClear = () => {
      setFilterDate({ from: '', to: '' });
      setStatusFilter('');
    };
 
    const handleReset = () => {
      setFilterDate({ from: '', to: '' });
      setStatusFilter('');
      setLanguageFilter({...languageFilter, from: "", to: "", status: ""});
      setIsOpen(false);
    };
 
    const [languageData, setLanguageData] = useState([]);
    const dispatch = useDispatch();
 
    const navigate = useNavigate();
    const [deleteId, setDeleteId]= useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(1);
   
    const [dropDownLanguageManage, setDropDownLanguageManage] = useState({
      id: 0,
      open: false
    });
    const [deleteLanguageDrop, setDeleteLanguageDrop] = useState(false);
 
    const handleDropDown = (e) => {
      let value = parseInt(e.currentTarget.dataset.languageId);
      if(dropDownLanguageManage.id === value){
        setDropDownLanguageManage({...dropDownLanguageManage, open: !(dropDownLanguageManage.open)})
      }
 
      if(dropDownLanguageManage.id !== value){
        setDropDownLanguageManage({id: value, open: true});
      }
    };
 
    const changePage = (count, type) => {
      if(type === "page"){
        setLanguageFilter({...languageFilter, page: count});
      }
 
      if(type === "show"){
        setLanguageFilter({...languageFilter, page: "1", limit: count});
        setPagination({...pagination, showing: count});
      }
    };
 
    useEffect(() => {
 
    const token = localStorage.getItem("token");
    // const languageData = JSON.parse(localStorage.getItem("user_data"));
 
    const getLanguageData = async () => {
      setContentLoader(true);
      try {
        const getLanguageList = await axios.get(`${baseUrl}admin/language/list`, {
          params: {
            from: languageFilter.from,
            to: languageFilter.to,
            status: statusFilter,
            search: languageFilter.search,
            sort: languageFilter.sorting,
            page: languageFilter.page,
            limit: languageFilter.limit
          },
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
 
        const result = getLanguageList;
        if(result.data.status === 200){
          setContentLoader(result.data.data.languages.length === 0 ? true : false);
          setLanguageData(result.data.data.languages);
          setPagination({
            ...pagination,
            currentPage: result.data.data.pagination.currentPage,
            totalPage: result.data.data.pagination.totalPage,
          });
          setContentLoader(false);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    getLanguageData();
    }, [languageFilter, refresh]);
 
    const handleDeleteLanguage = async () => {
      if(deleteId != null){
 
        try{
          const token = localStorage.getItem("token");
          const data = await axios({
            method: 'POST',
            url: `${baseUrl}admin/language/delete/${deleteId}`,
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });
 
        const result = data;
          if(result.data.status === 200){
            setLoading(false);
            setDeleteLanguageDrop(false);
            dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
            setDropDownLanguageManage({...dropDownLanguageManage, open: false});
            setRefresh(refresh+1);
          }else{
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
            setLoading(false);
            setDeleteLanguageDrop(false);
          }
 
        }catch (error){
 
          console.log(error);
          setDeleteLanguageDrop(false);
          if(error.response.data.status === 422){
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
            setLoading(false);
            return;
          }
 
          console.log(error.response.data.errors.password);
 
          if("password" in error.response.data.errors){
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.errors.password[0]}));
            setLoading(false);
            return;
          }
        }
      }else{
      }
    }
 
  return (
    <div className={`${Styles.language}`}>
 
      {
        deleteLanguageDrop && (
        <div className={`${Styles.deleteLanguage}`}>
          <div className={`${Styles.innerDeleteLanguage}`}>
            <div className={`${Styles.heading}`}>
              <p>Do you really want to delete this Language?</p>
            </div>
 
            <div className={`${Styles.delete_image}`}>
              <img src={DeleteIllustration} alt="not supported" />
            </div>
 
            <div className={`${Styles.buttons}`}>
              <button onClick={handleDeleteLanguage} disabled={loading}>{loading ? <AiOutlineLoading3Quarters /> : "Yes, Delete Language"}</button>
              <button onClick={() => {setDeleteLanguageDrop(false); setDropDownLanguageManage({id: 0, open: false});}}>Skip & Continue</button>
            </div>
          </div>
        </div>
        )
      }
 
      <div className={`${Styles.language_heading}`}>
        <h2>Language</h2>
      </div>
 
      <div className={`${Styles.language_nav}`}>
 
        {/* Filter And Search Data */}
        <div className={`${Styles.filter_search_btn}`}>
            <div className={`${Styles.filter_sort}`}>
                <div className={`${Styles.filter}`}>
                  <button onClick={()=> {setIsOpen(!isOpen); if(isSortOpen) setIsSortOpen(false);}}><MdFilterListAlt /> Filter</button>
                  {isOpen && (
                  <div className={`${Styles.filter_dropdown}`}>
                    <div className={`${Styles.heading}`}>
                      <p>Filter</p>
                      <IoMdClose onClick={()=> setIsOpen(!isOpen)}/>
                    </div>
 
                    {/* <div className={`${Styles.selectDate}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Date</p>
                        <p onClick={handleClear}>Clear</p>
                      </div>
                      <div className={`${Styles.filter_date}`}>
                        <div className={`${Styles.from}`}>
                          <label htmlFor="from">From:</label>
                          <input type="date" id='from' placeholder='00-00-0000' value={filterDate.from} onChange={(e) => setFilterDate({ ...filterDate, from: e.target.value })} />
                        </div>
                        <div className={`${Styles.to}`}>
                          <label htmlFor="to">To:</label>
                          <input type="date" id='to' placeholder='00-00-0000' value={filterDate.to} onChange={(e) => setFilterDate({ ...filterDate, to: e.target.value })} />
                        </div>
                      </div>
                    </div> */}
 
                    <div className={`${Styles.selectStatus}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Status</p>
                        <p onClick={() => setStatusFilter('')}>Clear</p>
                      </div>
                      <select name="statusFilter" id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                        <option value="">**** Select Status ****</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
 
                    <div className={`${Styles.buttons}`}>
                      <button onClick={handleReset}>Reset</button>
                      <button onClick={dateStatusApply}>Apply Now</button>
                    </div>
                  </div>
                  )}
                </div>
 
                <div className={`${Styles.sort}`}>
                  <button onClick={()=> {setIsSortOpen(!isSortOpen); if(isOpen) setIsOpen(false);}}><BsSortDown /> Sort</button>
                  {isSortOpen && (
                    <div className={`${Styles.sort_dropdown}`}>
                      <p onClick={() => {setLanguageFilter({...languageFilter, sorting: "a_to_z"}); setIsSortOpen(false);}}><span>A to Z</span><span>{(languageFilter.sorting === "a_to_z") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setLanguageFilter({...languageFilter, sorting: "z_to_a"}); setIsSortOpen(false);}}><span>Z to A</span><span>{(languageFilter.sorting === "z_to_a") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setLanguageFilter({...languageFilter, sorting: "desc"}); setIsSortOpen(false);}}><span>Latest Date</span><span>{(languageFilter.sorting === "desc") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setLanguageFilter({...languageFilter, sorting: "asc"}); setIsSortOpen(false);}}><span>Oldest Date</span><span>{(languageFilter.sorting === "asc") ? <BiCheck /> : ""}</span></p>
                    </div>
                  )}
                </div>
            </div>
            <div className={`${Styles.search}`}>
              <input type="text" placeholder="Search" onInput={(e) => setLanguageFilter({...languageFilter, search: e.target.value})} />
              <button onClick={() => navigate("/language/create")}> <LuPlusCircle /> Add Language</button>
            </div>
        </div>
 
        {/* Language Table */}
        <div className={`${Styles.language_List}`}>
            <div className={`${Styles.language_table}`}>
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Language Name</th>
                    <th>Status</th>
                    <th>Created Date</th>
                    <th>Updated Date</th>
                    <th></th>
                  </tr>
                </thead>  
                <tbody>
                  {/* Show loading spinner if content is still being loaded */}
                  {contentLoader && (
                    <tr>
                      <td colSpan="7">
                        <div className={`${Styles.dataNotFound}`}>
                          <img height="40" width="40" src={ContentLoaderGif} alt="Loading..." />
                        </div>
                      </td>
                    </tr>
                  )}
 
                  {/* Show "User not found" if no users are available after loading */}
                  {!contentLoader && languageData.length === 0 && (
                    <tr>
                      <td colSpan="7">
                        <div className={`${Styles.dataNotFound}`}>
                          <p>Language not found</p>
                        </div>
                      </td>
                    </tr>
                  )}
                  {languageData.length > 0 && languageData.map((data) => (
                    <tr key={data.id}>
                      <td>{data.id}</td>
                      {/* <td className={`${Styles.language_name}`}>
                        <p>{data.name}</p>
                      </td> */}
                      <td className={`${Styles.language_name}`}>
                      <div className={`${Styles.language_info}`}>
                        <img
                          className={`${Styles.language_img}`}
                          src={data.image_url ? data.image_url : DefaultLanguageImage}
                          alt={data.name}
                        />
                        <p>{data.name}</p>
                      </div>
                    </td>
                      <td>{data.status === 1 ? "Active" : "Inactive"}</td>
                      <td>{new Date(data.createdAt).toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}).replace(/\s+/g, '-')}</td>
                      <td>{new Date(data.updatedAt).toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}).replace(/\s+/g, '-')}</td>
                      <td className={`${Styles.edit_language}`}><FiEdit onClick={() => navigate(`/language/edit/${data.id}`)}/></td>
                      {/* <td>
                        <div className={`${Styles.manage}`}>
                          <PiDotsThreeOutlineFill data-languageId={data.id} onClick={handleDropDown} />
                          {
                            dropDownLanguageManage.id == data.id && dropDownLanguageManage.open === true && (
                              <div className={`${Styles.manageLanguage}`}>
                                <p onClick={() => navigate(`/Language/edit/${data.id}`)} ><span><FiEdit /></span> <span>Edit</span></p>
                                <p onClick={() => {setDeleteLanguageDrop(!deleteLanguageDrop); setDeleteId(data.id)} }><span><RiDeleteBin6Line /></span> <span>Delete</span></p>
                              </div>
                            )
                          }
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        </div>
 
        {/* Pagination */}
        <div>
          <Pagination data={pagination}  pageClick={changePage}/>
        </div>
      </div>
    </div>
  )
}
 
export default Language