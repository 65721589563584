import React, { useEffect, useState } from 'react';
import Styles from '../../assets/css/components/channel/channelList.module.scss';
import PlaylistCard from "../../components/playlist/PlaylistCard";
import { LuPlusCircle } from "react-icons/lu"; 
import { BsSortDown } from "react-icons/bs";
import { MdFilterListAlt } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { BiCheck } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { baseUrl } from '../../utils/constant';
import ContentLoaderGif from "../../assets/images/contentLoader.gif";

const ChannelList = () => {


    const [isOpen, setIsOpen] = useState(false);
    const [isSortOpen, setIsSortOpen] = useState(false);
    const navigate = useNavigate();
    const [statusFilter, setStatusFilter] = useState('');
    const [contentLoader, setContentLoader] = useState('true');


    const [filterDate, setFilterDate] = useState({
        from: '',
        to: ''
      });
      const [channelFilter, setChannelFilter] = useState({
        from: '',
        to: '',
        search: '',
        sorting: 'desc'
      });
  
      const dateStatusApply = () => {
        setChannelFilter({...channelFilter, from: filterDate.from, to: filterDate.to, status: statusFilter});
        setIsOpen(false);
      }
  
      const handleClear = () => {
        setFilterDate({ from: '', to: '' });
        setStatusFilter('');
      };
      
      const handleReset = () => {
        setFilterDate({ from: '', to: '' });
        setStatusFilter('');
        setChannelFilter({...channelFilter, from: "", to: "", status: ""});
        setIsOpen(false);
      };
  
      const [channelData, setChannelData] = useState([]);

      useEffect(() => {

        const token = localStorage.getItem("token");

        const getChannelData = async () => {
          setContentLoader(true);

          try {
            const getChannel = await axios.get(`${baseUrl}admin/channel/list?from=${channelFilter.from}&to=${channelFilter.to}&status=${statusFilter}&search=${channelFilter.search}&sort=${channelFilter.sorting}&page=${1}&limit=${1000}`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            });
      
            const result = getChannel;
            console.log("Run Success : ",result);
            if(result.data.status === 200){
              setContentLoader(result.data.data.channels.length === 0 ? true : false);
              setChannelData(result.data.data.channels);
              setContentLoader(false);
            }
          } catch (error) {
            console.log("Error : ", error);
          }
        }
        getChannelData();
      }, [channelFilter]);

      console.log("Content Loader Status : ",contentLoader);



  return (
    <div className={`${Styles.channels}`}>
  
          {/* Filter And Search Data */}
          <div className={`${Styles.filter_search_btn}`}>
            <div className={`${Styles.filter_sort}`}>
                <div className={`${Styles.filter}`}>
                  <button onClick={()=> {setIsOpen(!isOpen); if(isSortOpen) setIsSortOpen(false);}}><MdFilterListAlt /> Filter</button>
                  {isOpen && (
                  <div className={`${Styles.filter_dropdown}`}>
                    <div className={`${Styles.heading}`}>
                      <p>Filter</p>
                      <IoMdClose onClick={()=> setIsOpen(!isOpen)}/>
                    </div>
  
                    <div className={`${Styles.selectDate}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Date</p>
                        <p onClick={handleClear}>Clear</p>
                      </div>
                      <div className={`${Styles.filter_date}`}>
                        <div className={`${Styles.from}`}>
                          <label htmlFor="from">From:</label>
                          <input type="date" id='from' placeholder='00-00-0000' value={filterDate.from} onChange={(e) => setFilterDate({ ...filterDate, from: e.target.value })} />
                        </div>
                        <div className={`${Styles.to}`}>
                          <label htmlFor="to">To:</label>
                          <input type="date" id='to' placeholder='00-00-0000' value={filterDate.to} onChange={(e) => setFilterDate({ ...filterDate, to: e.target.value })} />
                        </div>
                      </div>
                    </div>
  
                    <div className={`${Styles.selectStatus}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Status</p>
                        <p onClick={() => setStatusFilter('')}>Clear</p>
                      </div>
                      <select name="statusFilter" id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                        <option value="">**** Select Status ****</option>
                        <option value="1">Publish</option>
                        <option value="0">Unpublish</option>
                      </select>
                    </div>
  
                    <div className={`${Styles.buttons}`}>
                      <button onClick={handleReset}>Reset</button>
                      <button onClick={dateStatusApply}>Apply Now</button>
                    </div>
                  </div>
                  )}
                </div>
  
                <div className={`${Styles.sort}`}>
                  <button onClick={()=> {setIsSortOpen(!isSortOpen); if(isOpen) setIsOpen(false);}}><BsSortDown /> Sort</button>
                  {isSortOpen && (
                    <div className={`${Styles.sort_dropdown}`}>
                      <p onClick={() => {setChannelFilter({...channelFilter, sorting: "a_to_z"}); setIsSortOpen(false);}}><span>A to Z</span><span>{(channelFilter.sorting === "a_to_z") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setChannelFilter({...channelFilter, sorting: "z_to_a"}); setIsSortOpen(false);}}><span>Z to A</span><span>{(channelFilter.sorting === "z_to_a") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setChannelFilter({...channelFilter, sorting: "desc"}); setIsSortOpen(false);}}><span>Latest Date</span><span>{(channelFilter.sorting === "desc") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setChannelFilter({...channelFilter, sorting: "asc"}); setIsSortOpen(false);}}><span>Oldest Date</span><span>{(channelFilter.sorting === "asc") ? <BiCheck /> : ""}</span></p>
                    </div>
                  )}
                </div>
            </div>
            <div className={`${Styles.search}`}>
              <input type="text" placeholder="Search" onInput={(e) => setChannelFilter({...channelFilter, search: e.target.value})} />
              <button onClick={() => navigate("/channel/create")}> <LuPlusCircle /> Create Channel</button>
            </div>
          </div>

          


          {/* Showing The Card List */}
          <div className={`${Styles.cards}`}>
            {contentLoader && (
              <div className={`${Styles.dataNotFound}`}>
                <img height="40" width="40" src={ContentLoaderGif} alt="Loading..." />
              </div>
            )}
            {!contentLoader && channelData.length === 0 && (
              <div className={`${Styles.dataNotFound}`}>
                <p>Channel not found</p>
              </div>
            )}
            {
                channelData.length > 0 && channelData.map((data, index) => {
                return (
                  <PlaylistCard
                    img={data.channel_profile_image}
                    id={data.id}
                    title={data.channel_name}
                    description={data.description}
                    playlistCount={data.total_playlists}
                    // status={data.status === 1 ? "Publish" : "Unpublish"}
                    status={data.status}
                    songs={data.total_music_tracks}
                  />
                )
              })
            }
          </div>
  
          {/* Pagination For The Album */}
          <div className={`${Styles.pagination}`}> 
              {/* pagination */}
          </div>
      </div>
  )
}

export default ChannelList
