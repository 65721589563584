import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/category/categoryEdit.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { useDispatch } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const CategoryEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading]= useState(false);

  const [ categoryData, setCategoryData] = useState({
    id: null,
    name: "",
    status: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    const getCategoryData = async () => {

      try {

        const getCategoryList = await axios.get(`${baseUrl}admin/category/get/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = getCategoryList;
        console.log(result);
        if(result.data.status === 200){
        setCategoryData({
            id: result.data.data.category.id,
            name: result.data.data.category.name,
            status: result.data.data.category.status,
          });
          
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    getCategoryData();
  }, []);

  const handleUpdateData = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    if(categoryData.name === "" || categoryData.status === ""){
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
    }

    const formDataSet = new FormData();
    formDataSet.append('id', categoryData.id);
    formDataSet.append('name', categoryData.name);
    formDataSet.append('status', categoryData.status);

    try{

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}admin/category/update`,
        data: {
            "id": categoryData.id,
            "name": categoryData.name,
            "status": categoryData.status,
        },
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      console.log("Update : ", result);
      if(result.data.status === 200){
        setLoading(false);
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        navigate("/category");

      }else{
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);

      }
    }catch (error){

      console.log(error);
      
      if(error.response.data.status === 422){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 404){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 500){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  }

  return (
    <div className={`${Styles.category}`}>

        <div className={`${Styles.category_heading}`}>
            <h2>Category</h2>
        </div>
  
        <div className={`${Styles.categoryEdit}`}>

            <div className={`${Styles.form_heading}`}>
              <h3>Edit Category</h3>
              <button onClick={() => navigate("/category")}>
                <RiArrowLeftSLine /> Go To Category
              </button>
            </div>
    
            <div className={`${Styles.edit_form}`}>
              <div className={`${Styles.form_grid}`}>
                <div className={`${Styles.form_field}`}>
                  <label>Category Name</label>
                  <input type="text" placeholder="Enter Category Name" name="name" value={categoryData.name} onChange={(e)  => setCategoryData({...categoryData, name: e.target.value })} />
                </div>
                <div className={`${Styles.form_field}`}>
                  <label>Status</label>
                  <select name="status" value={categoryData.status} onChange={(e)  => setCategoryData({...categoryData, status: e.target.value })} >
                    <option value="">***** Select Status *****</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div>
  
  
              {/* <div className={`${Styles.form_grid}`}>
                <div className={`${Styles.form_field}`}>
                  <label>Date of Birth</label>
                  <input type="date" name="dob" value={categoryData.dob} onChange={(e)  => setCategoryData({...categoryData, dob: e.target.value })} readOnly />
                </div>
                <div className={`${Styles.form_field}`}>
                  <label>Status</label>
                  <select name="status" value={categoryData.status} onChange={(e)  => setCategoryData({...categoryData, status: e.target.value })} >
                    <option value="">***** Select Status *****</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div> */}
    
              <div className={`${Styles.buttons}`}>
                <button className={`${Styles.action}`} onClick={handleUpdateData} disabled={loading}>{loading ? <AiOutlineLoading3Quarters /> : "Update"}</button>
                <button className={`${Styles.cancel}`} onClick={() => navigate("/category")}>Cancel</button>
              </div>
            </div>
        </div>
      
    </div>
  )
}

export default CategoryEdit
