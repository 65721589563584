import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/support/supportEdit.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { useDispatch } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const SupportEdit = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [loading, setLoading]= useState(false);

    const [ issueData, setIssueData] = useState({
        id: null,
        issue_description: "",
        status: "",
    });

    useEffect(() => {
        const token = localStorage.getItem("token");
    
        const getIssueData = async () => {
    
          try {
    
            const getIssueList = await axios.get(`${baseUrl}admin/support/get/${id}`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            });
    
            const result = getIssueList;
            console.log(result);
            if(result.data.status === 200){
                setIssueData({
                id: result.data.data.id,
                issue_description: result.data.data.issue_description,
                status: result.data.data.status,
              });
              
            }
          } catch (error) {
            console.log("Error : ", error);
          }
        }
        getIssueData();
      }, []);

      const handleUpdateData = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
    
        if(issueData.issue_description === "" || issueData.status === ""){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
        }
    
        const formDataSet = new FormData();
        formDataSet.append('id', issueData.id);
        formDataSet.append('issue_description', issueData.issue_description);
        formDataSet.append('status', issueData.status);
    
        try{
    
          const data = await axios({
            method: 'POST',
            url: `${baseUrl}admin/support/update`,
            data: {
                "id": issueData.id,
                "issue_description": issueData.issue_description,
                "status": issueData.status,
            },
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });

          const result = data;
      if(result.data.status === 200){
        setLoading(false);
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        navigate("/support");

      }else{
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);

      }
    }catch (error){

      console.log(error);
      
      if(error.response.data.status === 422){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 404){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 500){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  }

    return (
        <div  className={`${Styles.support_edit}`}>
            <div className={`${Styles.support_heading}`}>
                <h2>Support</h2>
            </div>

            <div className={`${Styles.checkIssue}`}>
    
                <div className={`${Styles.form_heading}`}>
                    <h3>Check Issue</h3>
                    <button onClick={() => navigate("/support")}>
                        <RiArrowLeftSLine /> Go To Support
                    </button>
                </div>

                <div className={`${Styles.edit_form}`}>
                    <div className={`${Styles.form_grid}`}>
                        <div className={`${Styles.form_field}`}>
                            <label>Issue Description</label>
                            <textarea type="text" placeholder="I miss the old algorithm; and wish there was a way to filter out songs less than 2 or 3 minutes long" name="name" value={issueData.issue_description} onChange={(e)  => setIssueData({...issueData, issue_description: e.target.value })} disabled />
                        </div>
                        <div className={`${Styles.form_field}`}>
                            <label>Status</label>
                            <select name="status" value={issueData.status} onChange={(e)  => setIssueData({...issueData, status: e.target.value })} >
                                <option value="">***** Select Status *****</option>
                                <option value="0">Pending</option>
                                <option value="1">Completed</option>
                            </select>
                        </div>
                    </div>

                    <div className={`${Styles.buttons}`}>
                        <button className={`${Styles.action}`} onClick={handleUpdateData} disabled={loading}>{loading ? <AiOutlineLoading3Quarters /> : "Update"}</button>
                        <button className={`${Styles.cancel}`} onClick={() => navigate("/support")}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportEdit
