import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/language/languageEdit.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { useDispatch } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import DefaultLanguageImage from "../../assets/images/Admin/Profile/languagelogo.jpg";
 
const LanguageEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [languageData, setLanguageData] = useState({
    id: null,
    name: "",
    status: "",
    image: "",
    preview: "",
  });
 
  useEffect(() => {
    const token = localStorage.getItem("token");
 
    const getLanguageData = async () => {
      try {
        const getLanguageList = await axios.get(`${baseUrl}admin/language/get/${id}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
 
        if (getLanguageList.data.status === 200) {
          const data = getLanguageList.data.data;
          setLanguageData({
            id: data.id,
            name: data.name,
            status: data.status,
            preview: data.image_url,
          });
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    };
 
    getLanguageData();
  }, [id]);
 
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        // Image size validation
        dispatch(
          popUpBoxTrigger({
            type: "error",
            heading: "Error",
            message: "Image size must be less than 2MB.",
          })
        );
        return;
      }
      setLanguageData({
        ...languageData,
        image: file,
        preview: URL.createObjectURL(file),
      });
    }
  };
 
  const handleUpdateData = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
 
    if (!languageData.name || languageData.status == "" ) {
      console.log("worked")
      console.log(languageData)
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please fill all the fields." }));
      setLoading(false);
      return;
    }
 
    const formDataSet = new FormData();
    formDataSet.append("id", languageData.id);
    formDataSet.append("name", languageData.name);
    formDataSet.append("status", languageData.status);
    if (languageData.image) {
      formDataSet.append("image", languageData.image);
    }
 
    try {
      const response = await axios.post(`${baseUrl}admin/language/update`, formDataSet, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });
 
      if (response.data.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: response.data.message }));
        navigate("/language");
      } else {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Oops! Something went wrong." }));
      }
    } catch (error) {
      console.error("Error: ", error);
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "An error occurred while updating data." }));
    } finally {
      setLoading(false);
    }
  };
 
  return (
    <div className={`${Styles.language}`}>
      <div className={`${Styles.language_heading}`}>
        <h2>Language</h2>
      </div>
 
      <div className={`${Styles.languageEdit}`}>
        <div className={`${Styles.form_heading}`}>
          <h3>Edit Language</h3>
          <button onClick={() => navigate("/language")}>
            <RiArrowLeftSLine /> Go To Language
          </button>
        </div>
 
        <div className={`${Styles.edit_form}`}>
          <div className={`${Styles.form_grid}`}>
            <div className={`${Styles.form_field}`}>
              <label>Language Name</label>
              <input
                type="text"
                placeholder="Enter Language Name"
                name="name"
                value={languageData.name}
                onChange={(e) => setLanguageData({ ...languageData, name: e.target.value })}
              />
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Status</label>
              <select
                name="status"
                value={languageData.status}
                onChange={(e) => setLanguageData({ ...languageData, status: e.target.value })}
              >
                <option value="">***** Select Status *****</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Language Image</label>
              <div className={`${Styles.imagePreview}`}>
                <input
                  type="file"
                  accept="image/*"
                  id="languageImage"
                  onChange={handleImageChange}
                  hidden
                />
                <label htmlFor="languageImage">
                  <img
                    src={languageData.preview || DefaultLanguageImage}
                    alt="Language Preview"
                    className={`${Styles.user_profile}`}
                    value={languageData.image}
                    onChange={(e) => setLanguageData({ ...languageData, status: e.target.value })}
                  />
                </label>
              </div>
            </div>
          </div>
 
          <div className={`${Styles.buttons}`}>
            <button className={`${Styles.action}`} onClick={handleUpdateData} disabled={loading}>
              {loading ? <AiOutlineLoading3Quarters /> : "Update"}
            </button>
            <button className={`${Styles.cancel}`} onClick={() => navigate("/language")}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default LanguageEdit;