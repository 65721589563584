import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/admin/category/category.module.scss";
import { IoMdClose } from "react-icons/io";
import { MdFilterListAlt } from "react-icons/md";
import { LuPlusCircle } from "react-icons/lu";
import { BsSortDown } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BiCheck } from "react-icons/bi";
// import { PiDotsThreeOutlineFill } from "react-icons/pi";
// import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import Pagination from "../common/Pagination";
import DeleteIllustration from "../../assets/images/deleteIllustrator.png";
import axios from "axios";
import { baseUrl } from '../../utils/constant';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { popUpBoxTrigger } from '../../features/message/popUpSlice';
import ContentLoaderGif from "../../assets/images/contentLoader.gif";

// import { updatePage } from '../../features/pagination/paginationSlice';

const Category = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [isSortOpen, setIsSortOpen] = useState(false);
    const [contentLoader, setContentLoader] = useState('true');

    // const [sortingValue, setSortingValue] = useState("asc");
  
  const [statusFilter, setStatusFilter] = useState('');
  const [filterDate, setFilterDate] = useState({
    from: '',
    to: ''
  });
  const [categoryFilter, setCategoryFilter] = useState({
    from: '',
    to: '',
    search: '',
    sorting: 'desc',
    page: "1",
    limit: "5"
  });

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPage: 6,
    showing: 5
  });

  const dateStatusApply = () => {
    setCategoryFilter({ ...categoryFilter, from: filterDate.from, to: filterDate.to, status: statusFilter });
    setIsOpen(false);
  };

  const handleClear = () => {
    setFilterDate({ from: '', to: '' });
    setStatusFilter('');
  };

  const handleReset = () => {
    setFilterDate({ from: '', to: '' });
    setStatusFilter('');
    setCategoryFilter({ ...categoryFilter, from: "", to: "", status: "" });
    setIsOpen(false);
  };

  const [categoryData, setCategoryData] = useState([]);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(1);

  const [dropDownCategoryManage, setDropDownCategoryManage] = useState({
    id: 0,
    open: false
  });
  const [deleteCategoryDrop, setDeleteCategoryDrop] = useState(false);

  const handleDropDown = (e) => {
    let value = parseInt(e.currentTarget.dataset.categoryId);
    if (dropDownCategoryManage.id === value) {
      setDropDownCategoryManage({ ...dropDownCategoryManage, open: !(dropDownCategoryManage.open) })
    }

    if (dropDownCategoryManage.id !== value) {
      setDropDownCategoryManage({ id: value, open: true });
    }
  };

  const changePage = (count, type) => {
    if (type === "page") {
      setCategoryFilter({ ...categoryFilter, page: count });
    }

    if (type === "show") {
      setCategoryFilter({ ...categoryFilter, page: "1", limit: count });
      setPagination({ ...pagination, showing: count });
    }
  };

  useEffect(() => {

    const token = localStorage.getItem("token");
    // const categoryData = JSON.parse(localStorage.getItem("user_data"));

    const getCategoryData = async () => {
      setContentLoader(true);
      try {
        const getCategoryList = await axios.get(`${baseUrl}admin/category/list`, {
          params: {
            from: categoryFilter.from,
            to: categoryFilter.to,
            status: statusFilter,
            search: categoryFilter.search,
            sort: categoryFilter.sorting,
            page: categoryFilter.page,
            limit: categoryFilter.limit
          },
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = getCategoryList;
        console.log(result);
        if(result.data.status === 200){
          setContentLoader(result.data.data.getCategory.length === 0 ? true : false);
          setCategoryData(result.data.data.getCategory);
          setPagination({
            ...pagination,
            currentPage: result.data.data.pagination.currentPage,
            totalPage: result.data.data.pagination.totalPage,
          });
          setContentLoader(false);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    getCategoryData();
  }, [categoryFilter, refresh]);

  const handleDeleteCategory = async () => {
    if (deleteId != null) {

      try {
        const token = localStorage.getItem("token");
        const data = await axios({
          method: 'POST',
          url: `${baseUrl}admin/category/delete/${deleteId}`,
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = data;
        if (result.data.status === 200) {
          setLoading(false);
          setDeleteCategoryDrop(false);
          dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
          setDropDownCategoryManage({ ...dropDownCategoryManage, open: false });
          setRefresh(refresh + 1);
        } else {
          dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
          setLoading(false);
          setDeleteCategoryDrop(false);
        }

      } catch (error) {

        console.log(error);
        setDeleteCategoryDrop(false);
        if (error.response.data.status === 422) {
          dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
          setLoading(false);
          return;
        }

        console.log(error.response.data.errors.password);

        if ("password" in error.response.data.errors) {
          dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.errors.password[0] }));
          setLoading(false);
          return;
        }
      }
    } else {
    }
  }

  return (
    <div className={`${Styles.category}`}>

      {
        deleteCategoryDrop && (
          <div className={`${Styles.deleteCategory}`}>
            <div className={`${Styles.innerDeleteCategory}`}>
              <div className={`${Styles.heading}`}>
                <p>Do you really want to delete this Category?</p>
              </div>

              <div className={`${Styles.delete_image}`}>
                <img src={DeleteIllustration} alt="not supported" />
              </div>

              <div className={`${Styles.buttons}`}>
                <button onClick={handleDeleteCategory} disabled={loading}>{loading ? <AiOutlineLoading3Quarters /> : "Yes, Delete Category"}</button>
                <button onClick={() => { setDeleteCategoryDrop(false); setDropDownCategoryManage({ id: 0, open: false }); }}>Skip & Continue</button>
              </div>
            </div>
          </div>
        )
      }

      <div className={`${Styles.category_heading}`}>
        <h2>Category</h2>
      </div>

      <div className={`${Styles.category_nav}`}>

        {/* Filter And Search Data */}
        <div className={`${Styles.filter_search_btn}`}>
          <div className={`${Styles.filter_sort}`}>
            <div className={`${Styles.filter}`}>
              <button onClick={() => { setIsOpen(!isOpen); if (isSortOpen) setIsSortOpen(false); }}><MdFilterListAlt /> Filter</button>
              {isOpen && (
                <div className={`${Styles.filter_dropdown}`}>
                  <div className={`${Styles.heading}`}>
                    <p>Filter</p>
                    <IoMdClose onClick={() => setIsOpen(!isOpen)} />
                  </div>

                  <div className={`${Styles.selectStatus}`}>
                    <div className={`${Styles.heading_clear}`}>
                      <p>Select Status</p>
                      <p onClick={() => setStatusFilter('')}>Clear</p>
                    </div>
                    <select name="statusFilter" id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                      <option value="">**** Select Status ****</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>

                  <div className={`${Styles.buttons}`}>
                    <button onClick={handleReset}>Reset</button>
                    <button onClick={dateStatusApply}>Apply Now</button>
                  </div>
                </div>
              )}
            </div>

            <div className={`${Styles.sort}`}>
              <button onClick={() => { setIsSortOpen(!isSortOpen); if (isOpen) setIsOpen(false); }}><BsSortDown /> Sort</button>
              {isSortOpen && (
                <div className={`${Styles.sort_dropdown}`}>
                  <p onClick={() => { setCategoryFilter({ ...categoryFilter, sorting: "a_to_z" }); setIsSortOpen(false); }}><span>A to Z</span><span>{(categoryFilter.sorting === "a_to_z") ? <BiCheck /> : ""}</span></p>
                  <p onClick={() => { setCategoryFilter({ ...categoryFilter, sorting: "z_to_a" }); setIsSortOpen(false); }}><span>Z to A</span><span>{(categoryFilter.sorting === "z_to_a") ? <BiCheck /> : ""}</span></p>
                  <p onClick={() => { setCategoryFilter({ ...categoryFilter, sorting: "desc" }); setIsSortOpen(false); }}><span>Latest Date</span><span>{(categoryFilter.sorting === "desc") ? <BiCheck /> : ""}</span></p>
                  <p onClick={() => { setCategoryFilter({ ...categoryFilter, sorting: "asc" }); setIsSortOpen(false); }}><span>Oldest Date</span><span>{(categoryFilter.sorting === "asc") ? <BiCheck /> : ""}</span></p>
                </div>
              )}
            </div>
          </div>
          <div className={`${Styles.search}`}>
            <input type="text" placeholder="Search" onInput={(e) => setCategoryFilter({ ...categoryFilter, search: e.target.value })} />
            <button onClick={() => navigate("/category/create")}> <LuPlusCircle /> Add Category</button>
          </div>
        </div>

        {/* Category Table */}
        <div className={`${Styles.category_List}`}>
            <div className={`${Styles.category_table}`}>
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Category Name</th>
                    <th>Status</th>
                    <th>Created Date</th>
                    <th>Updated Date</th>
                    <th></th>
                  </tr>
                </thead>  
                <tbody>
                  {/* Show loading spinner if content is still being loaded */}
                  {contentLoader && (
                    <tr>
                      <td colSpan="7">
                        <div className={`${Styles.dataNotFound}`}>
                          <img height="40" width="40" src={ContentLoaderGif} alt="Loading..." />
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* Show "User not found" if no users are available after loading */}
                  {!contentLoader && categoryData.length === 0 && (
                    <tr>
                      <td colSpan="7">
                        <div className={`${Styles.dataNotFound}`}>
                          <p>Category not found</p>
                        </div>
                      </td>
                    </tr>
                  )}
                  {categoryData.length > 0 && categoryData.map((data) => (
                    <tr key={data.id}>
                      <td>{data.id}</td>
                      <td>{data.name}</td>
                      <td>{data.status === 1 ? "Active" : "Inactive"}</td>
                      <td>{new Date(data.createdAt).toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}).replace(/\s+/g, '-')}</td>
                      <td>{new Date(data.updatedAt).toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}).replace(/\s+/g, '-')}</td>
                      <td className={`${Styles.edit_language}`}><FiEdit onClick={() => navigate(`/category/edit/${data.id}`)}/></td>
                      {/* <td>
                        <div className={`${Styles.manage}`}>
                          <PiDotsThreeOutlineFill data-categoryId={data.id} onClick={handleDropDown} />
                          {
                            dropDownCategoryManage.id == data.id && dropDownCategoryManage.open === true && (
                              <div className={`${Styles.manageCategory}`}>
                                <p onClick={() => navigate(`/category/edit/${data.id}`)} ><span><FiEdit /></span> <span>Edit</span></p>
                                <p onClick={() => {setDeleteCategoryDrop(!deleteCategoryDrop); setDeleteId(data.id)} }><span><RiDeleteBin6Line /></span> <span>Delete</span></p>
                              </div>
                            )
                          }
                        </div>
                      </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        <div>
          <Pagination data={pagination} pageClick={changePage} />
        </div>
      </div>
    </div>
  )
}

export default Category
